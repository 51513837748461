/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Grid,
  Typography,
  Breadcrumbs,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Modal,
  Link,
} from '@mui/material';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import { useNavigate, useParams } from 'react-router-dom';
import CustomSelect from 'components/CustomSelect/customSelect';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { qualificationFormSchema } from 'utils/ValidatorSchema';
import _ from 'lodash';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import { addNewData, getAllListData, updateData } from 'services/CommonServices';
import { SETTING_ENDPOINT } from 'utils/Constant';
import { useAppSelector } from 'store/hooks';
import CustomInput from 'components/CustomInput/customInput';
import { qualificationAddForm } from 'types';
import Loader from 'components/loader';
import DegreeAdd from '../AddDegree';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';

const QualificationAdd = () => {
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const { editId } = useParams();
  const navigate = useNavigate();
  const [Qualilis, setQualilis] = useState<any>([]);
  const [Degrilis, setDegrilis] = useState<any>([]);
  const [degreeval, setdegreeval] = useState<any>([]);
  const [removeddegree, setremoveddegree] = useState<any>([]);
  const [sameddegree, setsameddegree] = useState<any>([]);
  const [referbck, setreferbck] = useState<any>([]);
  const [removeId, setRemoveId] = useState<any>([]);
  const [loader, setLoader] = useState<any>(false);
  const [adminopen, setAdminOpen] = useState<any>(false);
  const [qualiload, setqualiload] = useState<any>(true);
  const [onceDegreeChanged, setDegreeChanged] = useState(false);

  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setValue,
    // setError,
    watch,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<qualificationAddForm>({
    criteriaMode: 'all',
    defaultValues: {
      qualification: '',
      degree: '',
      specializationData: [],
      status: 'active',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(qualificationFormSchema),
  });
  const {
    fields,
    append,
    remove,
    // replace,
  } = useFieldArray({
    control,
    name: 'specializationData',
  });

  const Degreeclbk = useCallback(
    async (id: string | number) => {
      setLoader(!false);
      try {
        const res = await getAllListData(
          `${SETTING_ENDPOINT.Degreedetails}?qualificationId=${id}`,
          true,
        );
        // console.log(res, 'weres');
        if (res && res.data) {
          setDegrilis([
            ...res.data.map((eg: any) => ({
              label: eg && eg.degree ? eg.degree : null,
              value: eg && String(eg.id).length > 0 ? eg.id : null,
            })),
          ]);
        }
      } catch (error) {
        setLoader(false);
        // setDegrilis([{ label: null, value: null }]);
      } finally {
        setLoader(false);
      }
    },
    [watch('qualification')],
  );

  const getInitialStatus = async () => {
    setLoader(!false);
    try {
      const resp: any = await getAllListData(
        `${SETTING_ENDPOINT.Qualificationdetails}?id=${editId}`,
        true,
      );

      if (resp && !_.isEmpty(resp.data)) {
        Degreeclbk(resp.data[0] && resp.data[0].qualification && resp.data[0].qualification?.id);
        setValue(
          'qualification',
          resp.data[0] && resp.data[0].qualification && resp.data[0].qualification?.id,
        );
        // const Mappedresults = resp.data[0].qualificationsDegree.map((rtu: any) => Number(rtu.degreeDetailsId));
        // console.log(Mappedresults,'Mappedresults');
        // setValue(
        //   'degree',Mappedresults
        // );

        setValue(
          'degree',
          resp?.data[0] && resp?.data[0] && resp?.data[0]?.id
            ? resp?.data[0] && resp?.data[0] && resp?.data[0]?.id
            : '',
        );
        // setdegreeval(
        //   resp.data[0] && resp.data[0] && resp.data[0].qualificationsDegree
        //     ? resp.data[0].qualificationsDegree.map((rtu: any) => Number(rtu.degreeDetailsId))
        //     : [],
        // );
        // setValue(
        //   'degree',[16,17,19],
        // );
        const spec: any = [];
        resp.data[0] &&
          resp.data[0].specializations &&
          resp.data[0].specializations.map((e: any) => {
            spec.push({
              specialization: e.specialization ? e.specialization : '',
              itemId: e.id,
            });
          });
        setValue('specializationData', spec);
        setValue('status', resp.data[0].status ? 'active' : 'inactive');
        setreferbck(resp.data ? resp.data : []);
        setLoader(false);
      }
    } catch (e) {
      if (e === 'Forbidden resource') {
        toast.error(AccessDenied);
        navigate('/dashboard');
      }
      // console.log(error);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };

  const onSubmitForm = (data: any) => {
    setLoader(true);
    // console.log(data, 'subdata');
    // if (!_.isUndefined(editId) && !_.isEmpty(referbck)) {
    const Spec: any = [];
    data.specializationData.map((es: any) => {
      Spec.push({
        specialization: es.specialization,
        ...(es.itemId ? { id: es.itemId ? es.itemId : '' } : {}),
      });
    });

    const removedeguuids: Array<any> = [];

    //  sameddegree
    // const samedeguuids: Array<any> = [];

    // const newapi = watch('degree');
    // const oldapi: number[] =
    //   referbck[0] && referbck[0].qualificationsDegree
    //     ? _.uniq(
    //         referbck[0].qualificationsDegree.map((elmtd: any) => {
    //           return Number(elmtd.degreeDetailsId);
    //         }),
    //       )
    //     : [];

    // oldapi.map((rty: any) => {
    //   if (!newapi.includes(Number(rty))) {
    //     removedeguuids.push(Number(rty));
    //   } else {
    //     samedeguuids.push(Number(rty));
    //   }
    // });

    // console.log(oldapi, 'oldapi');
    // console.log(newapi, 'newapi');

    // console.log(removedeguuids, 'removedeguuids');
    // console.log(samedeguuids, 'samedeguuids');

    const preapiRemove: any = [];
    const preapiSame: any = [];

    _.uniq(removeddegree).forEach((elmt: any) => {
      referbck[0].qualificationsDegree.map((elj: any) => {
        if (Number(elmt) === Number(elj.degreeDetailsId)) {
          preapiRemove.push(elj.id);
        }
      });
    });

    // const notSame: any = [];
    // _.uniq(_.xor(samedeguuids, newapi)).forEach((elmt: any) => {
    //   // const locarefbk = referbck[0].qualificationsDegree.find((yu:any) => yu && Number(yu.degreeDetailsId)=== Number(elmt));
    //   referbck[0].qualificationsDegree.map((elj: any) => {
    //     notSame.push({ degreeDetailsId: String(elmt) });
    //   });
    // });

    // _.uniq(samedeguuids).forEach((elmt: any) => {
    //   referbck[0].qualificationsDegree.map((elj: any) => {
    //     const locarefbk = referbck[0].qualificationsDegree.find(
    //       (yu: any) => yu && Number(yu.degreeDetailsId) === Number(elmt),
    //     );
    //     // console.log(locarefbk,'locarefbk');
    //     preapiSame.push({
    //       degreeDetailsId: String(elmt),
    //       id: locarefbk && locarefbk.id ? locarefbk.id : '',
    //     });
    //   });
    // });

    // const presame2 = _.uniqBy(
    //   [..._.uniqBy(preapiSame, 'degreeDetailsId'), ..._.uniqBy(notSame, 'degreeDetailsId')],
    //   'degreeDetailsId',
    // );
    // console.log(notSame, 'preapiSame');
    // console.log(preapiSame, 'preapiSame');
    // console.log(presame2, 'presame2');
    //   updateData(
    //     editId,
    //     [5, 6, 7].includes(Number(watch('qualification')))
    //       ? {
    //           organizationId: orgdy,
    //           qualificationDetailsId: Number(data.qualification),
    //           removeSpecializationIds:
    //             referbck[0] && referbck[0].qualificationSpecialization
    //               ? referbck[0].qualificationSpecialization.map((rt1: any) => rt1 && rt1.id)
    //               : [],
    //           specializationData: [],
    //           status: data.status === 'active' ? true : false,
    //         }
    //       : {
    //           organizationId: orgdy,
    //           qualificationDetailsId: data.qualification,
    //           degreeId: data.degree,
    //           removeSpecializationIds: removeId,
    //           specializationData: Spec,
    //           status: data.status === 'active' ? true : false,
    //         },
    //     `${String(SETTING_ENDPOINT.Qualification)}`,
    //     true,
    //   )
    //     .then(() => {
    //       // toast.success('Branch Details Updated Successfully', alertOptions);

    //       // if (Number(watch('qualification')) === 5) {
    //       //   setValue('specializationData', [{ itemId: undefined, specialization: '' }]);
    //       //   setValue('degree', []);
    //       // }
    //       resetForm();
    //       navigate(`${AdminPrivateRoutes.SETTINGS.QUALIFICATION.QUALIFICATIONLIST}`);
    //       setLoader(false);
    //     })
    //     .catch((e: any) => {
    //       if (e === 'Forbidden resource') {
    //         toast.error(AccessDenied);
    //         navigate('/dashboard');
    //       }
    //       // if (Number(watch('qualification')) === 5) {
    //       //   setValue('specializationData', [{ itemId: undefined, specialization: '' }]);
    //       //   setValue('degree', []);
    //       // }
    //       // console.log(e);
    //       setLoader(false);
    //     });
    // } else {
    addNewData(
      [5, 6, 7].includes(Number(watch('qualification')))
        ? {
            organizationId: orgdy,
            qualificationId: Number(data.qualification),
            status: data.status === 'active' ? true : false,
            isAdd: editId ? false : true,
          }
        : {
            organizationId: orgdy,
            qualificationId: Number(data.qualification),
            ...(removeId ? { removeSpecializationIds: removeId } : {}),
            degreeId: data.degree,
            specializationData: Spec,
            status: data.status === 'active' ? true : false,
            isAdd: editId ? false : true,
          },
      `${String(SETTING_ENDPOINT.Qualification)}`,
      '',
      true,
    )
      .then(() => {
        // toast.success('Branch Created Successfully', alertOptions);
        resetForm();
        navigate(`${AdminPrivateRoutes.SETTINGS.QUALIFICATION.QUALIFICATIONLIST}`);
        setLoader(false);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        // console.log(e);
        setLoader(false);
      });
    // }
  };
  const addSpecialization = () => {
    append({
      specialization: '',
      itemId: '',
    });
  };
  const removeSpecialization = (index: number, id: string) => {
    if (id) {
      removeId.push(id);
      setRemoveId([...removeId]);
    }
    remove(index);
  };

  async function Dropdownlis() {
    setLoader(!false);
    try {
      const res = await getAllListData(`${SETTING_ENDPOINT.Qualification}`, true);
      // console.log(res, 'res');
      if (res && res.data) {
        setQualilis(
          res.data
            .filter((e: any) => ![5, 6, 7].includes(e.id))
            .map((eg: any) => ({
              label: eg && eg.name ? eg.name : null,
              value: eg && String(eg.id).length > 0 ? eg.id : null,
            })),
        );
      }
    } catch (error) {
      setLoader(false);
      // setQualilis([{ label: null, value: null }]);
    } finally {
      setLoader(false);
    }
  }

  useLayoutEffect(() => {
    Dropdownlis();
  }, []);
  useEffect(() => {
    if (editId) {
      getInitialStatus();
    } else {
      setValue('specializationData', [
        {
          specialization: '',
          itemId: '',
        },
      ]);
    }
  }, []);

  useEffect(() => {
    // console.log(editId, _.isEmpty(editId), 'editId');
    Degreeclbk(Number(watch('qualification')));
    if ([5, 6, 7].includes(Number(watch('qualification'))) && !_.isEmpty(editId) && qualiload) {
      setValue('degree', '');
      setValue('specializationData', [{ itemId: undefined, specialization: '' }]);
      return;
    }
    if ([5, 6, 7].includes(Number(watch('qualification')))) {
      setValue('degree', '');

      [5, 6, 7].includes(Number(watch('qualification')))
        ? setValue('specializationData', [{ itemId: undefined, specialization: 'undefined' }])
        : setValue('specializationData', [{ itemId: undefined, specialization: '' }]);
      return;
    } else {
      const wtdspez: any = getValues('specializationData');
      const degreez: any = getValues('degree');

      setValue('degree', getValues('degree'));

      setValue(
        'specializationData',
        wtdspez &&
          wtdspez[0] &&
          wtdspez[0].specialization &&
          wtdspez[0].specialization === 'undefined'
          ? [{ itemId: undefined, specialization: '' }]
          : [...wtdspez],
      );
    }
    // setValue('degree', []);
    // setValue('specializationData', [{ itemId: undefined, specialization: '' }]);
  }, [watch('qualification')]);

  useEffect(() => {
    // console.log('quali', watch('qualification'));
    if (String(watch('qualification')).length <= 0) {
      setValue('degree', '');
    }
  }, [watch('qualification')]);

  useEffect(() => {
    // Trigger a validation for degree input when qualification is removed
    const degree: any = Array.isArray(watch('degree')) ? watch('degree') : [];
    if (String(watch('qualification')).length <= 0 && onceDegreeChanged && degree.length === 0) {
      trigger('degree');
    }
  }, [watch('degree'), watch('qualification'), onceDegreeChanged]);

  // useEffect(() => {
  //   // degreeval
  //   // console.log(watch('degree'), 'degree');
  //   if (watch('degree')) {
  //     const samevald = _.intersection(watch('degree') as Array<number>, degreeval);
  //     setsameddegree(samevald);

  //     // const difference =    _.uniq(_.union(watch('degree') as Array<number>,degreeval));
  //     const difference = _.uniq(_.difference(degreeval, watch('degree') as Array<number>));
  //     setremoveddegree(difference);

  //     // console.log(_.uniq(difference), 'difference');
  //     // console.log(_.uniq(samevald), 'samevald');
  //   }
  // }, [watch('degree')]);

  const handleOpen = () => setAdminOpen(true);

  const handleClose = () => {
    setAdminOpen(false);
    Degreeclbk(Number(watch('qualification')));
  };

  return (
    <Grid container>
      {loader && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Link underline='hover' color='inherit'>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Settings
            </Link>
            <Link
              underline='hover'
              color='inherit'
              href=''
              onClick={() => navigate('/settings/qualification')}>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Qualification
            </Link>
            <Typography color='text.primary'>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              {editId ? 'Edit' : 'Add'} Qualification
            </Typography>
          </Breadcrumbs>
        </Typography>
        <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
          <Typography className='page-title'>{editId ? 'Edit' : 'Add'} Qualification</Typography>
          <Typography component={'div'} className='d-flex-a'>
            <Button
              className='cancel-btn mr-16'
              onClick={() => navigate('/settings/qualification')}>
              {' '}
              Cancel
            </Button>
            <Button form={'qualification-add'} className='s-add-btn' type='submit'>
              {' '}
              Save
            </Button>
          </Typography>
        </Typography>
        <Typography component={'div'} className='main-content'>
          <Typography component={'div'} className='white-box'>
            <form
              id={'qualification-add'}
              className='private-form'
              onSubmit={handleSubmit(onSubmitForm)}>
              <Grid container>
                <Grid item md={8} sm={12} xs={12}>
                  <Grid container columnSpacing={4.25}>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Qualification <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={''}
                          name='qualification'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={Qualilis}
                                disabled={editId ? true : false}
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  setqualiload(false);
                                  onChange(e);
                                }}
                                placeHolder='Select your qualification'
                                error={errors.qualification?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography
                        component={'div'}
                        className='custom-field'
                        style={{
                          display: [5, 6, 7].includes(Number(watch('qualification')))
                            ? 'none'
                            : 'inherit',
                        }}>
                        <Typography component={'p'}>
                          Degree <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='degree'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                disabled={editId ? true : false}
                                options={
                                  Degrilis?.length
                                    ? [
                                        {
                                          label: '+ Add Degree',
                                          value: 'AddDegree',
                                          isDisabled: true,
                                          isFixed: true,
                                          isSelectable: false,
                                          custmdwn: true,
                                        },
                                        ...Degrilis,
                                      ]
                                    : [
                                        {
                                          label: '+ Add Degree',
                                          value: 'AddDegree',
                                          isDisabled: true,
                                          isFixed: true,
                                          isSelectable: false,
                                          custmdwn: true,
                                        },
                                      ]
                                }
                                name={name}
                                value={value}
                                custmfn={handleOpen}
                                onChange={(e) => {
                                  setDegreeChanged(true);
                                  onChange(e);
                                }}
                                placeHolder='Select your degrees'
                                error={errors.degree?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography
                        component={'div'}
                        className='custom-field'
                        sx={{
                          display: [5, 6, 7].includes(Number(watch('qualification')))
                            ? 'none'
                            : 'inherit',
                        }}>
                        <Typography component={'p'}>
                          Specialization <span>*</span>
                        </Typography>

                        {fields && fields.length >= 0 ? (
                          fields.map((item: any, index: number) => {
                            return (
                              <Grid container columnSpacing={4.25} key={item.id}>
                                <Grid item md={6} sm={12} xs={12} className=' mb-16'>
                                  <Controller
                                    control={control}
                                    defaultValue={''}
                                    name={`specializationData.${index}.specialization`}
                                    render={({ field: { onChange, value, name } }) => {
                                      return (
                                        <CustomInput
                                          value={value}
                                          name={name}
                                          className='custom-input'
                                          placeHolder='Specialization'
                                          error={
                                            errors?.specializationData &&
                                            Array.isArray(errors.specializationData)
                                              ? errors.specializationData[index]?.specialization
                                                  ?.message || ''
                                              : ''
                                          }
                                          onChange={(e) => {
                                            // console.log(value, 'spec');
                                            onChange(e);
                                          }}
                                        />
                                      );
                                    }}
                                  />
                                </Grid>
                                {index !== 0 ? (
                                  <Grid item md={6} sm={12} xs={12} className='mb-16 pt-14'>
                                    <Typography
                                      component={'div'}
                                      onClick={() => removeSpecialization(index, item.itemId)}>
                                      <Typography component={'div'} className='add-link pointer'>
                                        <span style={{ color: 'blue', opacity: '0.824' }}>
                                          - Delete Specialization
                                        </span>
                                      </Typography>
                                    </Typography>
                                  </Grid>
                                ) : (
                                  <Grid item md={6} sm={12} xs={12} className='mb-16 pt-14'>
                                    <Typography component={'div'} onClick={addSpecialization}>
                                      <Typography component={'div'} className='add-link pointer'>
                                        <span style={{ color: 'blue' }}>+ Add Specialization</span>
                                      </Typography>
                                    </Typography>
                                  </Grid>
                                )}
                              </Grid>
                            );
                          })
                        ) : (
                          <Grid item md={6} sm={12} xs={12} className='mb-16 pt-14'>
                            <Typography component={'div'} onClick={addSpecialization}>
                              <Typography component={'div'} className='add-link pointer'>
                                <span style={{ color: '#EE4B22' }}>+ Add Specialization</span>
                              </Typography>
                            </Typography>
                          </Grid>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Status <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=''
                          name='status'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <RadioGroup
                                className='custom-radio'
                                row
                                aria-labelledby=''
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}>
                                <FormControlLabel
                                  value='active'
                                  control={<Radio />}
                                  label='Active'
                                  checked={value === 'active'}
                                />
                                <FormControlLabel
                                  value='inactive'
                                  control={<Radio />}
                                  label='Inactive'
                                  checked={value === 'inactive'}
                                />
                              </RadioGroup>
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Typography>
        </Typography>
        <Modal
          sx={{ zIndex: 11000 }}
          open={adminopen}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <DegreeAdd close={handleClose} qualiId={watch('qualification')} />
        </Modal>
      </Grid>
    </Grid>
  );
};
export default QualificationAdd;

import React, { useCallback, useRef, useState } from 'react';
import CloseIcon from 'assets/png/close.svg';
import {
  Box,
  Button,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Loader from 'components/loader';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { alertOptions } from 'utils/Config';
import { addNewData, getAllListData } from 'services/CommonServices';
import FileSaver from 'file-saver';
import { AccessDenied } from 'utils/helper';
import { useNavigate } from 'react-router-dom';
import Deletebucket from 'assets/png/Deletebucket.svg';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import Xlsxpng from 'assets/png/xlsx.png';
import Fileupload from 'assets/png/upload-cloud.svg';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ImportFunctionSchema } from 'utils/ValidatorSchema';

const ImportFunctionality = ({ importUpload, setImportUpload, name, header }: any) => {
  const [loader, setLoader] = useState<any>(false);
  const [preview, setPreview] = useState<any>(false);
  const [data, setData] = useState<any>({ errorData: [], sucessData: [] });
  const navigate = useNavigate();
  const [fileUpload, setfileupload] = useState<any>('');
  const hiddenFileInput: any = useRef(null);
  const { handleSubmit } = useForm({ resolver: yupResolver(ImportFunctionSchema) });
  const handlePreviewClose = () => {
    setPreview(false);
  };
  function getSampleUrl(name: string): string {
    return `${name}/download-sample`;
  }
  function getDuplicateUrl(name: string): string {
    return `${name}/check-duplicate`;
  }
  function getFinalUrl(name: string): string {
    return `${name}/import`;
  }
  const downloadSample = () => {
    getAllListData(getSampleUrl(name), true, {
      responseType: 'arraybuffer',
    })
      .then((res: any) => {
        const date = Date.now();
        const data = new Blob([res]);
        FileSaver.saveAs(data, `${name}_sample_${date}.xlsx`);
        setLoader(false);
      })
      .finally(() => setLoader(false));
  };
  const onSubmit = () => {
    setLoader(true);
    if (!fileUpload) {
      toast.error('File is required', alertOptions);
      setLoader(false);
      return;
    }

    const formdata = new FormData();
    formdata.append('file', fileUpload[0]);

    addNewData(
      formdata,
      getDuplicateUrl(name),
      {
        'Content-Type': 'multipart/form-data',
      },
      true,
    )
      .then((res: any) => {
        if (res) {
          setData({ errorData: res.errors, sucessData: res.success });
          setPreview(true);
          handleClose();
          // console.log(res);
        }
        setLoader(false);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLoader(false);
        console.log(e);
      });
  };
  const finalSubmit = () => {
    addNewData(data.sucessData, getFinalUrl(name), {}, true)
      .then((res: any) => {
        if (res) {
          setData({ errorData: res.errors, sucessData: res.success });
          setPreview(false);
          handleClose();
          // console.log(res);
        }
        setLoader(false);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLoader(false);
        console.log(e);
      });
  };
  const onDrop = useCallback((acceptedFiles: any) => {
    onFileChange(acceptedFiles);
    // eslint-disable-next-line prettier/prettier, react-hooks/exhaustive-deps
  }, []);

  const onFileChange = (file: FileList) => {
    if (file[0] && file[0].size) {
      if (file[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        let fileSize = 0;
        fileSize = Math.round(file[0].size / 1024);
        if (fileSize > 10240) {
          toast.error('File is too big, Please select a file less than 10MB', alertOptions);
          return;
        }
      } else {
        toast.error('You can upload documents of types XLSX only', alertOptions);
        return;
      }
    }
    setfileupload(file);
    hiddenFileInput.current.value = '';
  };

  const handledelete = () => {
    setfileupload('');
  };
  const handleClose = () => {
    setfileupload('');
    setImportUpload(false);
  };
  return (
    <>
      {loader && <Loader />}
      <Modal open={importUpload} onClose={handleClose}>
        <Box className='logout-modal p-32'>
          <Typography
            component={'div'}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              height: '1rem',
            }}>
            <Typography className='text-right'>
              <img
                src={CloseIcon}
                alt='close'
                style={{ cursor: 'pointer' }}
                onClick={handleClose}
              />
            </Typography>
          </Typography>
          <Typography
            sx={{ fontSize: '20px', fontWeight: '600', textAlign: 'center', marginBottom: '1rem' }}>
            Import {name}
          </Typography>
          <form id='offer-status' className='private-form' onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='custom-field'>
                  <Typography className='link-color'>
                    <div onClick={downloadSample}>Download sample format</div>
                  </Typography>
                  <Dropzone onDrop={onDrop}>
                    {({ getRootProps, getInputProps }) => {
                      return (
                        <div
                          {...getRootProps({
                            className: 'dropzone container container-position pointer',
                            onClick: () => {
                              hiddenFileInput.current?.click();
                            },
                          })}>
                          <div>
                            <input {...getInputProps()} />
                            <p className='img-container'>
                              <img src={Fileupload} alt='file upload' />
                            </p>
                            <div className='fil-uplod-desc'>
                              <p className='first-heading'>
                                <span className='frst-span' role='button'>
                                  Click to upload
                                </span>{' '}
                                <span className='drago'>or drag and drop</span>
                              </p>
                              <p className='docx'>Upload only XLSX (up to 10MB)</p>
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  </Dropzone>
                </Typography>
                <div>
                  {fileUpload ? (
                    <Grid item md={12} sm={12} xs={12} style={{ display: 'inherit' }}>
                      <Typography component={'div'} className='custom-field file-view-box'>
                        <Typography component={'p'} className='first-children first-green-children'>
                          <div className='image-container'>
                            <span className='image image-success'>
                              <img
                                style={{ width: '16px', height: '16px' }}
                                src={Xlsxpng}
                                alt='Wordpng'
                              />
                            </span>
                            <span className={'texted'}>
                              <TableTooltip
                                value={
                                  fileUpload && fileUpload[0]
                                    ? fileUpload[0]?.name
                                    : fileUpload
                                      ? fileUpload.fileName
                                      : ''
                                }
                                positionfile={true}></TableTooltip>
                            </span>
                          </div>
                          <div className='Crossicon Crossicon-deletebucket' onClick={handledelete}>
                            <img src={Deletebucket} alt='Crossicon' />
                          </div>
                        </Typography>
                      </Typography>
                    </Grid>
                  ) : (
                    ''
                  )}
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Typography
                  component={'div'}
                  sx={{ justifyContent: 'center' }}
                  className='d-flex-a'>
                  <Button className='cancel-btn mr-16' onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button form={'offer-status'} className='s-add-btn' type='submit'>
                    Submit
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>

      <Modal open={preview} onClose={handlePreviewClose} sx={{ width: '100%' }}>
        <Box className='logout-modal p-32' sx={{ width: '100%' }}>
          <Typography component={'div'}>
            <Typography
              className='text-right'
              sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: '600',
                }}>
                List of {name} Imports
              </Typography>
              <img
                src={CloseIcon}
                alt='close'
                style={{ cursor: 'pointer' }}
                onClick={handlePreviewClose}
              />
            </Typography>
          </Typography>
          <Typography
            component={'div'}
            sx={{ maxHeight: '400px', overflow: 'auto', display: 'flex' }}>
            {data && data?.errorData?.length ? (
              <TableContainer
                sx={{ border: '1px solid #E4E7EC', borderRadius: '8px', marginTop: '1rem' }}>
                <Table className='custom-table' aria-label='simple table'>
                  <TableHead>
                    {header &&
                      header.map((e: any) => {
                        return (
                          <TableCell key={e} align='left'>
                            {e}
                          </TableCell>
                        );
                      })}
                    <TableCell>Remarks</TableCell>
                  </TableHead>
                  <TableBody>
                    {data?.errorData.map((errors: any, i: number) => (
                      <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                          }}>
                          {errors?.branchName ?? '-'}
                        </TableCell>
                        <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                          {errors?.branchType ?? '-'}
                        </TableCell>
                        <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                          {errors?.status ?? '-'}
                        </TableCell>
                        <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                          {errors?.remarks ?? '-'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              ''
            )}
            {data && data?.sucessData?.length ? (
              <TableContainer
                sx={{
                  border: '1px solid #E4E7EC',
                  borderRadius: '8px',
                  marginTop: '1rem',
                  marginLeft: '10px',
                }}>
                <Table className='custom-table' aria-label='simple table'>
                  <TableHead>
                    {header &&
                      header.map((e: any) => {
                        return (
                          <TableCell key={e} align='left'>
                            {e}
                          </TableCell>
                        );
                      })}
                  </TableHead>
                  <TableBody>
                    {data?.sucessData.map((errors: any, i: number) => (
                      <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                          }}>
                          {errors?.branchName ?? '-'}
                        </TableCell>
                        <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                          {errors?.branchType ?? '-'}
                        </TableCell>
                        <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                          {errors?.status ?? '-'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              ''
            )}
          </Typography>
          <Grid item md={12} sm={12} xs={12}>
            <Typography
              component={'div'}
              sx={{ justifyContent: 'end', marginTop: '20px' }}
              className='d-flex-a'>
              <Button className='cancel-btn mr-16' onClick={handlePreviewClose}>
                Cancel
              </Button>
              <Button className='s-add-btn' onClick={() => finalSubmit}>
                Submit
              </Button>
            </Typography>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
export default ImportFunctionality;
